@import "helpers";
@import "fontawesome-helper";
@import "icomoon-var";

:root {
  --color-accent-alt: #de2629;
  --color-content-bg: @color-text;
  --color-control-border: #dbdbdb;
  --color-control-border-hover: #767676;
  --color-control-extra: #ccc;
  --color-control-disabled: #ccc;
  --color-text-lighter: #999999;

  --color-header-bg: #ffffff;
  --color-panel-bg: @color-accent-lighter;

  --color-accent: @color-accent-alt;
  --color-accent-dark: #ed272a;
  --color-accent-darker: #d42629;
  --color-border: #eaeaea;
  --color-border-light: #f4f4f4;
  --color-text: #212121;
  --color-text-light: #666666;
  --color-bg: #f4f4f4;
  --color-selected: #C7E0F4; //#EFF6FC
  --color-hover: #EAEAEA;
  --color-hover-hover: #c8c8c8;

  --color-accent-light: #f2f2f2;
  --color-accent-lighter: #fcf9f9;

  --color-control-selected: #eaeaea;
  --color-control-hover: #f4f4f4;

  --color-positive: #6aa50b;
  --color-negative: #a80000;
}

@color-accent-alt: var(--color-accent-alt);
@color-content-bg: var(--color-content-bg);
@color-control-border: var(--color-control-border);
@color-control-border-hover: var(--color-control-border-hover);
@color-control-extra: var(--color-control-extra);
@color-control-disabled: var(--color-control-disabled);
@color-text-lighter: var(--color-text-lighter);

@color-header-bg: var(--color-header-bg);
@color-panel-bg: var(--color-panel-bg);

@color-accent: var(--color-accent);
@color-accent-dark: var(--color-accent-dark);
@color-accent-darker: var(--color-accent-darker);
@color-border: var(--color-border);
@color-border-light: var(--color-border-light);
@color-text: var(--color-text);
@color-text-light: var(--color-text-light);
@color-bg: var(--color-bg);
@color-selected: var(--color-selected);
@color-hover: var(--color-hover);
@color-hover-hover: var(--color-hover-hover);

@color-accent-light: var(--color-accent-light);
@color-accent-lighter: var(--color-accent-lighter);

@color-control-selected: var(--color-control-selected);
@color-control-hover: var(--color-control-hover);

@color-positive: var(--color-positive);
@color-negative: var(--color-negative);

@font-family-regular: 'OpenSans';

@font-size-xxx-small: 8px;
@font-size-xx-small: 10px;
@font-size-x-small: 12px;
@font-size-small: 14px;
@font-size-normal: 16px;
@font-size-large: 18px;
@font-size-x-large: 20px;
@font-size-xx-large: 24px;
@font-size-xxx-large: 30px;
@font-size-xxxx-large: 40px;
@font-size-giant: 60px;

@media-breakpoint-phones: 576px;
@media-breakpoint-tablets: 768px;
