@import "../../../../node_modules/@fortawesome/fontawesome-free/less/v4-shims.less";
@import "../../../../node_modules/@fortawesome/fontawesome-free/less/fontawesome.less";
@import (less) "../../../../node_modules/@fortawesome/fontawesome-free/less/solid.less";
@import (less) "../../../../node_modules/@fortawesome/fontawesome-free/less/regular.less";
@import (less) "../../../../node_modules/@fortawesome/fontawesome-free/less/brands.less";
//@import "../../../../../../../node_modules/font-awesome/less/font-awesome";
//@import "../../../../../../../node_modules/font-awesome/less/variables.less";
//@import "../../../../../../../node_modules/font-awesome/less/mixins.less";
//@import "../../../../../../../node_modules/font-awesome/less/path.less";
//@import "../../../../../../../node_modules/font-awesome/less/core.less";
//@import "../../../../../../../node_modules/font-awesome/less/larger.less";
//@import "../../../../../../../node_modules/font-awesome/less/fixed-width.less";
//@import "../../../../../../../node_modules/font-awesome/less/list.less";
//@import "../../../../../../../node_modules/font-awesome/less/bordered-pulled.less";
//@import "../../../../../../../node_modules/font-awesome/less/animated.less";
//@import "../../../../../../../node_modules/font-awesome/less/rotated-flipped.less";
//@import "../../../../../../../node_modules/font-awesome/less/stacked.less";
//@import "../../../../../../../node_modules/font-awesome/less/screen-reader.less";

.fa-lg()
{
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -15%;
}

.fa-2x
{ font-size: 2em; }

.fa-3x
{ font-size: 3em; }

.fa-4x
{ font-size: 4em; }

.fa-5x
{ font-size: 5em; }

.fa-icon-help()
{
  .fa-icon();
  .fa;
}
.fa-mixin-icon(@icon){
  .fa;
  @var: "fa-var-@{icon}";
  &:before { content: @@var; }
}
//.fa-glass(){.fa-icon-help(); &:before { content: @fa-var-glass; }}

.fa-music()
{.fa-icon-help();
  &:before
  { content: @fa-var-music; }
}

.fa-search()
{.fa-icon-help();
  &:before
  { content: @fa-var-search; }
}

//.fa-envelope-o(){.fa-icon-help(); &:before { content: @fa-var-envelope-o; }}

.fa-envelope()
{.fa-icon-help();
  &:before
  { content: @fa-var-envelope; }
}

.fa-heart()
{.fa-icon-help();
  &:before
  { content: @fa-var-heart; }
}

.fa-star()
{.fa-icon-help();
  &:before
  { content: @fa-var-star; }
}

//.fa-star-o(){.fa-icon-help(); &:before { content: @fa-var-star-o; }}

.fa-user()
{.fa-icon-help();
  &:before
  { content: @fa-var-user; }
}

.fa-film()
{.fa-icon-help();
  &:before
  { content: @fa-var-film; }
}

.fa-th-large()
{.fa-icon-help();
  &:before
  { content: @fa-var-th-large; }
}

.fa-th()
{.fa-icon-help();
  &:before
  { content: @fa-var-th; }
}

.fa-th-list()
{.fa-icon-help();
  &:before
  { content: @fa-var-th-list; }
}

.fa-check()
{.fa-icon-help();
  &:before
  { content: @fa-var-check; }
}

.fa-times()
{.fa-icon-help();
  &:before
  { content: @fa-var-times; }
}

.fa-search-plus()
{.fa-icon-help();
  &:before
  { content: @fa-var-search-plus; }
}

.fa-search-minus()
{.fa-icon-help();
  &:before
  { content: @fa-var-search-minus; }
}

.fa-power-off()
{.fa-icon-help();
  &:before
  { content: @fa-var-power-off; }
}

.fa-signal()
{.fa-icon-help();
  &:before
  { content: @fa-var-signal; }
}

.fa-cog()
{.fa-icon-help();
  &:before
  { content: @fa-var-cog; }
}

//.fa-trash-o(){.fa-icon-help(); &:before { content: @fa-var-trash-o; }}

.fa-home()
{.fa-icon-help();
  &:before
  { content: @fa-var-home; }
}

//.fa-file-o(){.fa-icon-help(); &:before { content: @fa-var-file-o; }}

.fa-clock-o()
{.fa-icon-help();
  &:before
  { content: @fa-var-clock; }
}

.fa-road()
{.fa-icon-help();
  &:before
  { content: @fa-var-road; }
}

.fa-download()
{.fa-icon-help();
  &:before
  { content: @fa-var-download; }
}

//.fa-arrow-circle-o-down(){.fa-icon-help(); &:before { content: @fa-var-arrow-circle-o-down; }}

//.fa-arrow-circle-o-up(){.fa-icon-help(); &:before { content: @fa-var-arrow-circle-o-up; }}

.fa-inbox()
{.fa-icon-help();
  &:before
  { content: @fa-var-inbox; }
}

//.fa-play-circle-o(){.fa-icon-help(); &:before { content: @fa-var-play-circle-o; }}

//.fa-repeat(){.fa-icon-help(); &:before { content: @fa-var-repeat; }}

//.fa-refresh(){.fa-icon-help(); &:before { content: @fa-var-refresh; }}

.fa-list-alt()
{.fa-icon-help();
  &:before
  { content: @fa-var-list-alt; }
}

.fa-lock()
{.fa-icon-help();
  &:before
  { content: @fa-var-lock; }
}

.fa-flag()
{.fa-icon-help();
  &:before
  { content: @fa-var-flag; }
}

.fa-headphones()
{.fa-icon-help();
  &:before
  { content: @fa-var-headphones; }
}

.fa-volume-off()
{.fa-icon-help();
  &:before
  { content: @fa-var-volume-off; }
}

.fa-volume-down()
{.fa-icon-help();
  &:before
  { content: @fa-var-volume-down; }
}

.fa-volume-up()
{.fa-icon-help();
  &:before
  { content: @fa-var-volume-up; }
}

.fa-qrcode()
{.fa-icon-help();
  &:before
  { content: @fa-var-qrcode; }
}

.fa-barcode()
{.fa-icon-help();
  &:before
  { content: @fa-var-barcode; }
}

.fa-tag()
{.fa-icon-help();
  &:before
  { content: @fa-var-tag; }
}

.fa-tags()
{.fa-icon-help();
  &:before
  { content: @fa-var-tags; }
}

.fa-book()
{.fa-icon-help();
  &:before
  { content: @fa-var-book; }
}

.fa-bookmark()
{.fa-icon-help();
  &:before
  { content: @fa-var-bookmark; }
}

.fa-print()
{.fa-icon-help();
  &:before
  { content: @fa-var-print; }
}

.fa-camera()
{.fa-icon-help();
  &:before
  { content: @fa-var-camera; }
}

.fa-font()
{.fa-icon-help();
  &:before
  { content: @fa-var-font; }
}

.fa-bold()
{.fa-icon-help();
  &:before
  { content: @fa-var-bold; }
}

.fa-italic()
{.fa-icon-help();
  &:before
  { content: @fa-var-italic; }
}

.fa-text-height()
{.fa-icon-help();
  &:before
  { content: @fa-var-text-height; }
}

.fa-text-width()
{.fa-icon-help();
  &:before
  { content: @fa-var-text-width; }
}

.fa-align-left()
{.fa-icon-help();
  &:before
  { content: @fa-var-align-left; }
}

.fa-align-center()
{.fa-icon-help();
  &:before
  { content: @fa-var-align-center; }
}

.fa-align-right()
{.fa-icon-help();
  &:before
  { content: @fa-var-align-right; }
}

.fa-align-justify()
{.fa-icon-help();
  &:before
  { content: @fa-var-align-justify; }
}

.fa-list()
{.fa-icon-help();
  &:before
  { content: @fa-var-list; }
}

.fa-outdent()
{.fa-icon-help();
  &:before
  { content: @fa-var-outdent; }
}

.fa-indent()
{.fa-icon-help();
  &:before
  { content: @fa-var-indent; }
}

//.fa-video-camera(){.fa-icon-help(); &:before { content: @fa-var-video-camera; }}

//.fa-picture-o(){.fa-icon-help(); &:before { content: @fa-var-picture-o; }}

//.fa-pencil(){.fa-icon-help(); &:before { content: @fa-var-pencil; }}

.fa-map-marker()
{.fa-icon-help();
  &:before
  { content: @fa-var-map-marker; }
}

.fa-adjust()
{.fa-icon-help();
  &:before
  { content: @fa-var-adjust; }
}

.fa-tint()
{.fa-icon-help();
  &:before
  { content: @fa-var-tint; }
}

//.fa-pencil-square-o(){.fa-icon-help(); &:before { content: @fa-var-pencil-square-o; }}

//.fa-share-square-o(){.fa-icon-help(); &:before { content: @fa-var-share-square-o; }}

//.fa-check-square-o(){.fa-icon-help(); &:before { content: @fa-var-check-square-o; }}

//.fa-arrows(){.fa-icon-help(); &:before { content: @fa-var-arrows; }}

.fa-step-backward()
{.fa-icon-help();
  &:before
  { content: @fa-var-step-backward; }
}

.fa-fast-backward()
{.fa-icon-help();
  &:before
  { content: @fa-var-fast-backward; }
}

.fa-backward()
{.fa-icon-help();
  &:before
  { content: @fa-var-backward; }
}

.fa-play()
{.fa-icon-help();
  &:before
  { content: @fa-var-play; }
}

.fa-pause()
{.fa-icon-help();
  &:before
  { content: @fa-var-pause; }
}

.fa-stop()
{.fa-icon-help();
  &:before
  { content: @fa-var-stop; }
}

.fa-forward()
{.fa-icon-help();
  &:before
  { content: @fa-var-forward; }
}

.fa-fast-forward()
{.fa-icon-help();
  &:before
  { content: @fa-var-fast-forward; }
}

.fa-step-forward()
{.fa-icon-help();
  &:before
  { content: @fa-var-step-forward; }
}

.fa-eject()
{.fa-icon-help();
  &:before
  { content: @fa-var-eject; }
}

.fa-chevron-left()
{.fa-icon-help();
  &:before
  { content: @fa-var-chevron-left; }
}

.fa-chevron-right()
{.fa-icon-help();
  &:before
  { content: @fa-var-chevron-right; }
}

.fa-plus-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-plus-circle; }
}

.fa-minus-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-minus-circle; }
}

.fa-times-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-times-circle; }
}

.fa-check-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-check-circle; }
}

.fa-question-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-question-circle; }
}

.fa-info-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-info-circle; }
}

.fa-crosshairs()
{.fa-icon-help();
  &:before
  { content: @fa-var-crosshairs; }
}

//.fa-times-circle-o(){.fa-icon-help(); &:before { content: @fa-var-times-circle-o; }}

//.fa-check-circle-o(){.fa-icon-help(); &:before { content: @fa-var-check-circle-o; }}

.fa-ban()
{.fa-icon-help();
  &:before
  { content: @fa-var-ban; }
}

.fa-arrow-left()
{.fa-icon-help();
  &:before
  { content: @fa-var-arrow-left; }
}

.fa-arrow-right()
{.fa-icon-help();
  &:before
  { content: @fa-var-arrow-right; }
}

.fa-arrow-up()
{.fa-icon-help();
  &:before
  { content: @fa-var-arrow-up; }
}

.fa-arrow-down()
{.fa-icon-help();
  &:before
  { content: @fa-var-arrow-down; }
}

.fa-share()
{.fa-icon-help();
  &:before
  { content: @fa-var-share; }
}

.fa-expand()
{.fa-icon-help();
  &:before
  { content: @fa-var-expand; }
}

.fa-compress()
{.fa-icon-help();
  &:before
  { content: @fa-var-compress; }
}

.fa-plus()
{.fa-icon-help();
  &:before
  { content: @fa-var-plus; }
}

.fa-minus()
{.fa-icon-help();
  &:before
  { content: @fa-var-minus; }
}

.fa-asterisk()
{.fa-icon-help();
  &:before
  { content: @fa-var-asterisk; }
}

.fa-exclamation-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-exclamation-circle; }
}

.fa-gift()
{.fa-icon-help();
  &:before
  { content: @fa-var-gift; }
}

.fa-leaf()
{.fa-icon-help();
  &:before
  { content: @fa-var-leaf; }
}

.fa-fire()
{.fa-icon-help();
  &:before
  { content: @fa-var-fire; }
}

.fa-eye()
{.fa-icon-help();
  &:before
  { content: @fa-var-eye; }
}

.fa-eye-slash()
{.fa-icon-help();
  &:before
  { content: @fa-var-eye-slash; }
}

.fa-exclamation-triangle()
{.fa-icon-help();
  &:before
  { content: @fa-var-exclamation-triangle; }
}

.fa-plane()
{.fa-icon-help();
  &:before
  { content: @fa-var-plane; }
}

.fa-calendar()
{.fa-icon-help();
  &:before
  { content: @fa-var-calendar; }
}

.fa-random()
{.fa-icon-help();
  &:before
  { content: @fa-var-random; }
}

.fa-comment()
{.fa-icon-help();
  &:before
  { content: @fa-var-comment; }
}

.fa-magnet()
{.fa-icon-help();
  &:before
  { content: @fa-var-magnet; }
}

.fa-chevron-up()
{.fa-icon-help();
  &:before
  { content: @fa-var-chevron-up; }
}

.fa-chevron-down()
{
  .fa-icon-help();
  &:before
  { content: @fa-var-chevron-down; }
}

.fa-retweet()
{.fa-icon-help();
  &:before
  { content: @fa-var-retweet; }
}

.fa-shopping-cart()
{.fa-icon-help();
  &:before
  { content: @fa-var-shopping-cart; }
}

.fa-folder()
{.fa-icon-help();
  &:before
  { content: @fa-var-folder; }
}

.fa-folder-open()
{.fa-icon-help();
  &:before
  { content: @fa-var-folder-open; }
}

//.fa-arrows-v(){.fa-icon-help(); &:before { content: @fa-var-arrows-v; }}

//.fa-arrows-h(){.fa-icon-help(); &:before { content: @fa-var-arrows-h; }}

//.fa-bar-chart(){.fa-icon-help(); &:before { content: @fa-var-bar-chart; }}

.fa-twitter-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-twitter-square; }
}

.fa-facebook-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-facebook-square; }
}

.fa-camera-retro()
{.fa-icon-help();
  &:before
  { content: @fa-var-camera-retro; }
}

.fa-key()
{.fa-icon-help();
  &:before
  { content: @fa-var-key; }
}

.fa-cogs()
{.fa-icon-help();
  &:before
  { content: @fa-var-cogs; }
}

.fa-comments()
{.fa-icon-help();
  &:before
  { content: @fa-var-comments; }
}

//.fa-thumbs-o-up(){.fa-icon-help(); &:before { content: @fa-var-thumbs-o-up; }}

//.fa-thumbs-o-down(){.fa-icon-help(); &:before { content: @fa-var-thumbs-o-down; }}

.fa-star-half()
{.fa-icon-help();
  &:before
  { content: @fa-var-star-half; }
}

//.fa-heart-o(){.fa-icon-help(); &:before { content: @fa-var-heart-o; }}

.fa-sign-out()
{.fa-icon-help();
  &:before
  { content: @fa-var-sign-out-alt; }
}

//.fa-linkedin-square(){.fa-icon-help(); &:before { content: @fa-var-linkedin-square; }}

//.fa-thumb-tack(){.fa-icon-help(); &:before { content: @fa-var-thumb-tack; }}

//.fa-external-link(){.fa-icon-help(); &:before { content: @fa-var-external-link; }}

//.fa-sign-in(){.fa-icon-help(); &:before { content: @fa-var-sign-in; }}

.fa-trophy()
{.fa-icon-help();
  &:before
  { content: @fa-var-trophy; }
}

.fa-github-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-github-square; }
}

.fa-upload()
{.fa-icon-help();
  &:before
  { content: @fa-var-upload; }
}

//.fa-lemon-o(){.fa-icon-help(); &:before { content: @fa-var-lemon-o; }}

.fa-phone()
{.fa-icon-help();
  &:before
  { content: @fa-var-phone; }
}

//.fa-square-o(){.fa-icon-help(); &:before { content: @fa-var-square-o; }}

//.fa-bookmark-o(){.fa-icon-help(); &:before { content: @fa-var-bookmark-o; }}

.fa-phone-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-phone-square; }
}

.fa-twitter()
{.fa-icon-help();
  &:before
  { content: @fa-var-twitter; }
}

.fa-facebook()
{.fa-icon-help();
  &:before
  { content: @fa-var-facebook; }
}

.fa-github()
{.fa-icon-help();
  &:before
  { content: @fa-var-github; }
}

.fa-unlock()
{.fa-icon-help();
  &:before
  { content: @fa-var-unlock; }
}

.fa-credit-card()
{.fa-icon-help();
  &:before
  { content: @fa-var-credit-card; }
}

.fa-rss()
{.fa-icon-help();
  &:before
  { content: @fa-var-rss; }
}

//.fa-hdd-o(){.fa-icon-help(); &:before { content: @fa-var-hdd-o; }}

.fa-bullhorn()
{.fa-icon-help();
  &:before
  { content: @fa-var-bullhorn; }
}

.fa-bell()
{.fa-icon-help();
  &:before
  { content: @fa-var-bell; }
}

.fa-certificate()
{.fa-icon-help();
  &:before
  { content: @fa-var-certificate; }
}

//.fa-hand-o-right(){.fa-icon-help(); &:before { content: @fa-var-hand-o-right; }}

//.fa-hand-o-left(){.fa-icon-help(); &:before { content: @fa-var-hand-o-left; }}

//.fa-hand-o-up(){.fa-icon-help(); &:before { content: @fa-var-hand-o-up; }}

//.fa-hand-o-down(){.fa-icon-help(); &:before { content: @fa-var-hand-o-down; }}

.fa-arrow-circle-left()
{.fa-icon-help();
  &:before
  { content: @fa-var-arrow-circle-left; }
}

.fa-arrow-circle-right()
{.fa-icon-help();
  &:before
  { content: @fa-var-arrow-circle-right; }
}

.fa-arrow-circle-up()
{.fa-icon-help();
  &:before
  { content: @fa-var-arrow-circle-up; }
}

.fa-arrow-circle-down()
{.fa-icon-help();
  &:before
  { content: @fa-var-arrow-circle-down; }
}

.fa-globe()
{.fa-icon-help();
  &:before
  { content: @fa-var-globe; }
}

.fa-wrench()
{.fa-icon-help();
  &:before
  { content: @fa-var-wrench; }
}

.fa-tasks()
{.fa-icon-help();
  &:before
  { content: @fa-var-tasks; }
}

.fa-filter()
{.fa-icon-help();
  &:before
  { content: @fa-var-filter; }
}

.fa-briefcase()
{.fa-icon-help();
  &:before
  { content: @fa-var-briefcase; }
}

.fa-arrows-alt()
{.fa-icon-help();
  &:before
  { content: @fa-var-arrows-alt; }
}

.fa-users()
{.fa-icon-help();
  &:before
  { content: @fa-var-users; }
}

.fa-link()
{.fa-icon-help();
  &:before
  { content: @fa-var-link; }
}

.fa-cloud()
{.fa-icon-help();
  &:before
  { content: @fa-var-cloud; }
}

.fa-flask()
{.fa-icon-help();
  &:before
  { content: @fa-var-flask; }
}

//.fa-scissors(){.fa-icon-help(); &:before { content: @fa-var-scissors; }}

//.fa-files-o(){.fa-icon-help(); &:before { content: @fa-var-files-o; }}

.fa-paperclip()
{.fa-icon-help();
  &:before
  { content: @fa-var-paperclip; }
}

//.fa-floppy-o(){.fa-icon-help(); &:before { content: @fa-var-floppy-o; }}

.fa-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-square; }
}

.fa-bars()
{.fa-icon-help();
  &:before
  { content: @fa-var-bars; }
}

.fa-list-ul()
{.fa-icon-help();
  &:before
  { content: @fa-var-list-ul; }
}

.fa-list-ol()
{.fa-icon-help();
  &:before
  { content: @fa-var-list-ol; }
}

.fa-strikethrough()
{.fa-icon-help();
  &:before
  { content: @fa-var-strikethrough; }
}

.fa-underline()
{.fa-icon-help();
  &:before
  { content: @fa-var-underline; }
}

.fa-table()
{.fa-icon-help();
  &:before
  { content: @fa-var-table; }
}

.fa-magic()
{.fa-icon-help();
  &:before
  { content: @fa-var-magic; }
}

.fa-truck()
{.fa-icon-help();
  &:before
  { content: @fa-var-truck; }
}

.fa-pinterest()
{.fa-icon-help();
  &:before
  { content: @fa-var-pinterest; }
}

.fa-pinterest-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-pinterest-square; }
}

.fa-google-plus-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-google-plus-square; }
}

.fa-google-plus()
{.fa-icon-help();
  &:before
  { content: @fa-var-google-plus; }
}

//.fa-money(){.fa-icon-help(); &:before { content: @fa-var-money; }}

.fa-caret-down()
{.fa-icon-help();
  &:before
  { content: @fa-var-caret-down; }
}

.fa-caret-up()
{.fa-icon-help();
  &:before
  { content: @fa-var-caret-up; }
}

.fa-caret-left()
{.fa-icon-help();
  &:before
  { content: @fa-var-caret-left; }
}

.fa-caret-right()
{.fa-icon-help();
  &:before
  { content: @fa-var-caret-right; }
}

.fa-columns()
{.fa-icon-help();
  &:before
  { content: @fa-var-columns; }
}

.fa-sort()
{.fa-icon-help();
  &:before
  { content: @fa-var-sort; }
}

//.fa-sort-desc(){.fa-icon-help(); &:before { content: @fa-var-sort-desc; }}

//.fa-sort-asc(){.fa-icon-help(); &:before { content: @fa-var-sort-asc; }}

.fa-envelope()
{.fa-icon-help();
  &:before
  { content: @fa-var-envelope; }
}

.fa-linkedin()
{.fa-icon-help();
  &:before
  { content: @fa-var-linkedin; }
}

.fa-undo()
{.fa-icon-help();
  &:before
  { content: @fa-var-undo; }
}

.fa-gavel()
{.fa-icon-help();
  &:before
  { content: @fa-var-gavel; }
}

//.fa-tachometer(){.fa-icon-help(); &:before { content: @fa-var-tachometer; }}

//.fa-comment-o(){.fa-icon-help(); &:before { content: @fa-var-comment-o; }}

//.fa-comments-o(){.fa-icon-help(); &:before { content: @fa-var-comments-o; }}

.fa-bolt()
{.fa-icon-help();
  &:before
  { content: @fa-var-bolt; }
}

.fa-sitemap()
{.fa-icon-help();
  &:before
  { content: @fa-var-sitemap; }
}

.fa-umbrella()
{.fa-icon-help();
  &:before
  { content: @fa-var-umbrella; }
}

.fa-clipboard()
{.fa-icon-help();
  &:before
  { content: @fa-var-clipboard; }
}

//.fa-lightbulb-o(){.fa-icon-help(); &:before { content: @fa-var-lightbulb-o; }}

//.fa-exchange(){.fa-icon-help(); &:before { content: @fa-var-exchange; }}

//.fa-cloud-download(){.fa-icon-help(); &:before { content: @fa-var-cloud-download; }}

//.fa-cloud-upload(){.fa-icon-help(); &:before { content: @fa-var-cloud-upload; }}

.fa-user-md()
{.fa-icon-help();
  &:before
  { content: @fa-var-user-md; }
}

.fa-stethoscope()
{.fa-icon-help();
  &:before
  { content: @fa-var-stethoscope; }
}

.fa-suitcase()
{.fa-icon-help();
  &:before
  { content: @fa-var-suitcase; }
}

//.fa-bell-o(){.fa-icon-help(); &:before { content: @fa-var-bell-o; }}

.fa-coffee()
{.fa-icon-help();
  &:before
  { content: @fa-var-coffee; }
}

//.fa-cutlery(){.fa-icon-help(); &:before { content: @fa-var-cutlery; }}

//.fa-file-text-o(){.fa-icon-help(); &:before { content: @fa-var-file-text-o; }}

//.fa-building-o(){.fa-icon-help(); &:before { content: @fa-var-building-o; }}

//.fa-hospital-o(){.fa-icon-help(); &:before { content: @fa-var-hospital-o; }}

.fa-ambulance()
{.fa-icon-help();
  &:before
  { content: @fa-var-ambulance; }
}

.fa-medkit()
{.fa-icon-help();
  &:before
  { content: @fa-var-medkit; }
}

.fa-fighter-jet()
{.fa-icon-help();
  &:before
  { content: @fa-var-fighter-jet; }
}

.fa-beer()
{.fa-icon-help();
  &:before
  { content: @fa-var-beer; }
}

.fa-h-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-h-square; }
}

.fa-plus-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-plus-square; }
}

.fa-angle-double-left()
{.fa-icon-help();
  &:before
  { content: @fa-var-angle-double-left; }
}

.fa-angle-double-right()
{.fa-icon-help();
  &:before
  { content: @fa-var-angle-double-right; }
}

.fa-angle-double-up()
{.fa-icon-help();
  &:before
  { content: @fa-var-angle-double-up; }
}

.fa-angle-double-down()
{.fa-icon-help();
  &:before
  { content: @fa-var-angle-double-down; }
}

.fa-angle-left()
{.fa-icon-help();
  &:before
  { content: @fa-var-angle-left; }
}

.fa-angle-right()
{.fa-icon-help();
  &:before
  { content: @fa-var-angle-right; }
}

.fa-angle-up()
{.fa-icon-help();
  &:before
  { content: @fa-var-angle-up; }
}

.fa-angle-down()
{.fa-icon-help();
  &:before
  { content: @fa-var-angle-down; }
}

.fa-desktop()
{.fa-icon-help();
  &:before
  { content: @fa-var-desktop; }
}

.fa-laptop()
{.fa-icon-help();
  &:before
  { content: @fa-var-laptop; }
}

.fa-tablet()
{.fa-icon-help();
  &:before
  { content: @fa-var-tablet; }
}

.fa-mobile()
{.fa-icon-help();
  &:before
  { content: @fa-var-mobile; }
}

//.fa-circle-o(){.fa-icon-help(); &:before { content: @fa-var-circle-o; }}

.fa-quote-left()
{.fa-icon-help();
  &:before
  { content: @fa-var-quote-left; }
}

.fa-quote-right()
{.fa-icon-help();
  &:before
  { content: @fa-var-quote-right; }
}

.fa-spinner()
{.fa-icon-help();
  &:before
  { content: @fa-var-spinner; }
}

.fa-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-circle; }
}

.fa-reply()
{.fa-icon-help();
  &:before
  { content: @fa-var-reply; }
}

.fa-github-alt()
{.fa-icon-help();
  &:before
  { content: @fa-var-github-alt; }
}

//.fa-folder-o(){.fa-icon-help(); &:before { content: @fa-var-folder-o; }}

//.fa-folder-open-o(){.fa-icon-help(); &:before { content: @fa-var-folder-open-o; }}

//.fa-smile-o(){.fa-icon-help(); &:before { content: @fa-var-smile-o; }}

//.fa-frown-o(){.fa-icon-help(); &:before { content: @fa-var-frown-o; }}

//.fa-meh-o(){.fa-icon-help(); &:before { content: @fa-var-meh-o; }}

.fa-gamepad()
{.fa-icon-help();
  &:before
  { content: @fa-var-gamepad; }
}

//.fa-keyboard-o(){.fa-icon-help(); &:before { content: @fa-var-keyboard-o; }}

//.fa-flag-o(){.fa-icon-help(); &:before { content: @fa-var-flag-o; }}

.fa-flag-checkered()
{.fa-icon-help();
  &:before
  { content: @fa-var-flag-checkered; }
}

.fa-terminal()
{.fa-icon-help();
  &:before
  { content: @fa-var-terminal; }
}

.fa-code()
{.fa-icon-help();
  &:before
  { content: @fa-var-code; }
}

.fa-reply-all()
{.fa-icon-help();
  &:before
  { content: @fa-var-reply-all; }
}

//.fa-star-half-o(){.fa-icon-help(); &:before { content: @fa-var-star-half-o; }}

.fa-location-arrow()
{.fa-icon-help();
  &:before
  { content: @fa-var-location-arrow; }
}

.fa-crop()
{.fa-icon-help();
  &:before
  { content: @fa-var-crop; }
}

//.fa-code-fork(){.fa-icon-help(); &:before { content: @fa-var-code-fork; }}

//.fa-chain-broken(){.fa-icon-help(); &:before { content: @fa-var-chain-broken; }}

.fa-question()
{.fa-icon-help();
  &:before
  { content: @fa-var-question; }
}

.fa-info()
{.fa-icon-help();
  &:before
  { content: @fa-var-info; }
}

.fa-exclamation()
{.fa-icon-help();
  &:before
  { content: @fa-var-exclamation; }
}

.fa-superscript()
{.fa-icon-help();
  &:before
  { content: @fa-var-superscript; }
}

.fa-subscript()
{.fa-icon-help();
  &:before
  { content: @fa-var-subscript; }
}

.fa-eraser()
{.fa-icon-help();
  &:before
  { content: @fa-var-eraser; }
}

.fa-puzzle-piece()
{.fa-icon-help();
  &:before
  { content: @fa-var-puzzle-piece; }
}

.fa-microphone()
{.fa-icon-help();
  &:before
  { content: @fa-var-microphone; }
}

.fa-microphone-slash()
{.fa-icon-help();
  &:before
  { content: @fa-var-microphone-slash; }
}

//.fa-shield(){.fa-icon-help(); &:before { content: @fa-var-shield; }}

//.fa-calendar-o(){.fa-icon-help(); &:before { content: @fa-var-calendar-o; }}

.fa-fire-extinguisher()
{.fa-icon-help();
  &:before
  { content: @fa-var-fire-extinguisher; }
}

.fa-rocket()
{.fa-icon-help();
  &:before
  { content: @fa-var-rocket; }
}

.fa-maxcdn()
{.fa-icon-help();
  &:before
  { content: @fa-var-maxcdn; }
}

.fa-chevron-circle-left()
{.fa-icon-help();
  &:before
  { content: @fa-var-chevron-circle-left; }
}

.fa-chevron-circle-right()
{.fa-icon-help();
  &:before
  { content: @fa-var-chevron-circle-right; }
}

.fa-chevron-circle-up()
{.fa-icon-help();
  &:before
  { content: @fa-var-chevron-circle-up; }
}

.fa-chevron-circle-down()
{.fa-icon-help();
  &:before
  { content: @fa-var-chevron-circle-down; }
}

.fa-html5()
{.fa-icon-help();
  &:before
  { content: @fa-var-html5; }
}

.fa-css3()
{.fa-icon-help();
  &:before
  { content: @fa-var-css3; }
}

.fa-anchor()
{.fa-icon-help();
  &:before
  { content: @fa-var-anchor; }
}

.fa-unlock-alt()
{.fa-icon-help();
  &:before
  { content: @fa-var-unlock-alt; }
}

.fa-bullseye()
{.fa-icon-help();
  &:before
  { content: @fa-var-bullseye; }
}

.fa-ellipsis-h()
{.fa-icon-help();
  &:before
  { content: @fa-var-ellipsis-h; }
}

.fa-ellipsis-v()
{.fa-icon-help();
  &:before
  { content: @fa-var-ellipsis-v; }
}

.fa-rss-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-rss-square; }
}

.fa-play-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-play-circle; }
}

//.fa-ticket(){.fa-icon-help(); &:before { content: @fa-var-ticket; }}

.fa-minus-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-minus-square; }
}

//.fa-minus-square-o(){.fa-icon-help(); &:before { content: @fa-var-minus-square-o; }}

//.fa-level-up(){.fa-icon-help(); &:before { content: @fa-var-level-up; }}

//.fa-level-down(){.fa-icon-help(); &:before { content: @fa-var-level-down; }}

.fa-check-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-check-square; }
}

//.fa-pencil-square(){.fa-icon-help(); &:before { content: @fa-var-pencil-square; }}

//.fa-external-link-square(){.fa-icon-help(); &:before { content: @fa-var-external-link-square; }}

.fa-share-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-share-square; }
}

.fa-compass()
{.fa-icon-help();
  &:before
  { content: @fa-var-compass; }
}

//.fa-caret-square-o-down(){.fa-icon-help(); &:before { content: @fa-var-caret-square-o-down; }}

//.fa-caret-square-o-up(){.fa-icon-help(); &:before { content: @fa-var-caret-square-o-up; }}

//.fa-caret-square-o-right(){.fa-icon-help(); &:before { content: @fa-var-caret-square-o-right; }}

//.fa-eur(){.fa-icon-help(); &:before { content: @fa-var-eur; }}

//.fa-gbp(){.fa-icon-help(); &:before { content: @fa-var-gbp; }}

//.fa-usd(){.fa-icon-help(); &:before { content: @fa-var-usd; }}

//.fa-inr(){.fa-icon-help(); &:before { content: @fa-var-inr; }}

//.fa-jpy(){.fa-icon-help(); &:before { content: @fa-var-jpy; }}

//.fa-rub(){.fa-icon-help(); &:before { content: @fa-var-rub; }}

//.fa-krw(){.fa-icon-help(); &:before { content: @fa-var-krw; }}

.fa-btc()
{.fa-icon-help();
  &:before
  { content: @fa-var-btc; }
}

.fa-file()
{.fa-icon-help();
  &:before
  { content: @fa-var-file; }
}

//.fa-file-text(){.fa-icon-help(); &:before { content: @fa-var-file-text; }}

//.fa-sort-alpha-asc(){.fa-icon-help(); &:before { content: @fa-var-sort-alpha-asc; }}

//.fa-sort-alpha-desc(){.fa-icon-help(); &:before { content: @fa-var-sort-alpha-desc; }}

//.fa-sort-amount-asc(){.fa-icon-help(); &:before { content: @fa-var-sort-amount-asc; }}

//.fa-sort-amount-desc(){.fa-icon-help(); &:before { content: @fa-var-sort-amount-desc; }}

//.fa-sort-numeric-asc(){.fa-icon-help(); &:before { content: @fa-var-sort-numeric-asc; }}

//.fa-sort-numeric-desc(){.fa-icon-help(); &:before { content: @fa-var-sort-numeric-desc; }}

.fa-thumbs-up()
{.fa-icon-help();
  &:before
  { content: @fa-var-thumbs-up; }
}

.fa-thumbs-down()
{.fa-icon-help();
  &:before
  { content: @fa-var-thumbs-down; }
}

.fa-youtube-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-youtube-square; }
}

.fa-youtube()
{.fa-icon-help();
  &:before
  { content: @fa-var-youtube; }
}

.fa-xing()
{.fa-icon-help();
  &:before
  { content: @fa-var-xing; }
}

.fa-xing-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-xing-square; }
}

//.fa-youtube-play(){.fa-icon-help(); &:before { content: @fa-var-youtube-play; }}

.fa-dropbox()
{.fa-icon-help();
  &:before
  { content: @fa-var-dropbox; }
}

.fa-stack-overflow()
{.fa-icon-help();
  &:before
  { content: @fa-var-stack-overflow; }
}

.fa-instagram()
{.fa-icon-help();
  &:before
  { content: @fa-var-instagram; }
}

.fa-flickr()
{.fa-icon-help();
  &:before
  { content: @fa-var-flickr; }
}

.fa-adn()
{.fa-icon-help();
  &:before
  { content: @fa-var-adn; }
}

.fa-bitbucket()
{.fa-icon-help();
  &:before
  { content: @fa-var-bitbucket; }
}

//.fa-bitbucket-square(){.fa-icon-help(); &:before { content: @fa-var-bitbucket-square; }}

.fa-tumblr()
{.fa-icon-help();
  &:before
  { content: @fa-var-tumblr; }
}

.fa-tumblr-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-tumblr-square; }
}

//.fa-long-arrow-down(){.fa-icon-help(); &:before { content: @fa-var-long-arrow-down; }}

//.fa-long-arrow-up(){.fa-icon-help(); &:before { content: @fa-var-long-arrow-up; }}

//.fa-long-arrow-left(){.fa-icon-help(); &:before { content: @fa-var-long-arrow-left; }}

//.fa-long-arrow-right(){.fa-icon-help(); &:before { content: @fa-var-long-arrow-right; }}

.fa-apple()
{.fa-icon-help();
  &:before
  { content: @fa-var-apple; }
}

.fa-windows()
{.fa-icon-help();
  &:before
  { content: @fa-var-windows; }
}

.fa-android()
{.fa-icon-help();
  &:before
  { content: @fa-var-android; }
}

.fa-linux()
{.fa-icon-help();
  &:before
  { content: @fa-var-linux; }
}

.fa-dribbble()
{.fa-icon-help();
  &:before
  { content: @fa-var-dribbble; }
}

.fa-skype()
{.fa-icon-help();
  &:before
  { content: @fa-var-skype; }
}

.fa-foursquare()
{.fa-icon-help();
  &:before
  { content: @fa-var-foursquare; }
}

.fa-trello()
{.fa-icon-help();
  &:before
  { content: @fa-var-trello; }
}

.fa-female()
{.fa-icon-help();
  &:before
  { content: @fa-var-female; }
}

.fa-male()
{.fa-icon-help();
  &:before
  { content: @fa-var-male; }
}

.fa-gratipay()
{.fa-icon-help();
  &:before
  { content: @fa-var-gratipay; }
}

//.fa-sun-o(){.fa-icon-help(); &:before { content: @fa-var-sun-o; }}

//.fa-moon-o(){.fa-icon-help(); &:before { content: @fa-var-moon-o; }}

.fa-archive()
{.fa-icon-help();
  &:before
  { content: @fa-var-archive; }
}

.fa-bug()
{.fa-icon-help();
  &:before
  { content: @fa-var-bug; }
}

.fa-vk()
{.fa-icon-help();
  &:before
  { content: @fa-var-vk; }
}

.fa-weibo()
{.fa-icon-help();
  &:before
  { content: @fa-var-weibo; }
}

.fa-renren()
{.fa-icon-help();
  &:before
  { content: @fa-var-renren; }
}

.fa-pagelines()
{.fa-icon-help();
  &:before
  { content: @fa-var-pagelines; }
}

.fa-stack-exchange()
{.fa-icon-help();
  &:before
  { content: @fa-var-stack-exchange; }
}

//.fa-arrow-circle-o-right(){.fa-icon-help(); &:before { content: @fa-var-arrow-circle-o-right; }}

//.fa-arrow-circle-o-left(){.fa-icon-help(); &:before { content: @fa-var-arrow-circle-o-left; }}

//.fa-caret-square-o-left(){.fa-icon-help(); &:before { content: @fa-var-caret-square-o-left; }}

//.fa-dot-circle-o(){.fa-icon-help(); &:before { content: @fa-var-dot-circle-o; }}

.fa-wheelchair()
{.fa-icon-help();
  &:before
  { content: @fa-var-wheelchair; }
}

.fa-vimeo-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-vimeo-square; }
}

//.fa-try(){.fa-icon-help(); &:before { content: @fa-var-try; }}

//.fa-plus-square-o(){.fa-icon-help(); &:before { content: @fa-var-plus-square-o; }}

.fa-space-shuttle()
{.fa-icon-help();
  &:before
  { content: @fa-var-space-shuttle; }
}

.fa-slack()
{.fa-icon-help();
  &:before
  { content: @fa-var-slack; }
}

.fa-envelope-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-envelope-square; }
}

.fa-wordpress()
{.fa-icon-help();
  &:before
  { content: @fa-var-wordpress; }
}

.fa-openid()
{.fa-icon-help();
  &:before
  { content: @fa-var-openid; }
}

.fa-university()
{.fa-icon-help();
  &:before
  { content: @fa-var-university; }
}

.fa-graduation-cap()
{.fa-icon-help();
  &:before
  { content: @fa-var-graduation-cap; }
}

.fa-yahoo()
{.fa-icon-help();
  &:before
  { content: @fa-var-yahoo; }
}

.fa-google()
{.fa-icon-help();
  &:before
  { content: @fa-var-google; }
}

.fa-reddit()
{.fa-icon-help();
  &:before
  { content: @fa-var-reddit; }
}

.fa-reddit-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-reddit-square; }
}

.fa-stumbleupon-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-stumbleupon-circle; }
}

.fa-stumbleupon()
{.fa-icon-help();
  &:before
  { content: @fa-var-stumbleupon; }
}

.fa-delicious()
{.fa-icon-help();
  &:before
  { content: @fa-var-delicious; }
}

.fa-digg()
{.fa-icon-help();
  &:before
  { content: @fa-var-digg; }
}

.fa-pied-piper-pp()
{.fa-icon-help();
  &:before
  { content: @fa-var-pied-piper-pp; }
}

.fa-pied-piper-alt()
{.fa-icon-help();
  &:before
  { content: @fa-var-pied-piper-alt; }
}

.fa-drupal()
{.fa-icon-help();
  &:before
  { content: @fa-var-drupal; }
}

.fa-joomla()
{.fa-icon-help();
  &:before
  { content: @fa-var-joomla; }
}

.fa-language()
{.fa-icon-help();
  &:before
  { content: @fa-var-language; }
}

.fa-fax()
{.fa-icon-help();
  &:before
  { content: @fa-var-fax; }
}

.fa-building()
{.fa-icon-help();
  &:before
  { content: @fa-var-building; }
}

.fa-child()
{.fa-icon-help();
  &:before
  { content: @fa-var-child; }
}

.fa-paw()
{.fa-icon-help();
  &:before
  { content: @fa-var-paw; }
}

//.fa-spoon(){.fa-icon-help(); &:before { content: @fa-var-spoon; }}

.fa-cube()
{.fa-icon-help();
  &:before
  { content: @fa-var-cube; }
}

.fa-cubes()
{.fa-icon-help();
  &:before
  { content: @fa-var-cubes; }
}

.fa-behance()
{.fa-icon-help();
  &:before
  { content: @fa-var-behance; }
}

.fa-behance-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-behance-square; }
}

.fa-steam()
{.fa-icon-help();
  &:before
  { content: @fa-var-steam; }
}

.fa-steam-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-steam-square; }
}

.fa-recycle()
{.fa-icon-help();
  &:before
  { content: @fa-var-recycle; }
}

.fa-car()
{.fa-icon-help();
  &:before
  { content: @fa-var-car; }
}

.fa-taxi()
{.fa-icon-help();
  &:before
  { content: @fa-var-taxi; }
}

.fa-tree()
{.fa-icon-help();
  &:before
  { content: @fa-var-tree; }
}

.fa-spotify()
{.fa-icon-help();
  &:before
  { content: @fa-var-spotify; }
}

.fa-deviantart()
{.fa-icon-help();
  &:before
  { content: @fa-var-deviantart; }
}

.fa-soundcloud()
{.fa-icon-help();
  &:before
  { content: @fa-var-soundcloud; }
}

.fa-database()
{.fa-icon-help();
  &:before
  { content: @fa-var-database; }
}

//.fa-file-pdf-o(){.fa-icon-help(); &:before { content: @fa-var-file-pdf-o; }}

//.fa-file-word-o(){.fa-icon-help(); &:before { content: @fa-var-file-word-o; }}

//.fa-file-excel-o(){.fa-icon-help(); &:before { content: @fa-var-file-excel-o; }}

//.fa-file-powerpoint-o(){.fa-icon-help(); &:before { content: @fa-var-file-powerpoint-o; }}

//.fa-file-image-o(){.fa-icon-help(); &:before { content: @fa-var-file-image-o; }}

//.fa-file-archive-o(){.fa-icon-help(); &:before { content: @fa-var-file-archive-o; }}

//.fa-file-audio-o(){.fa-icon-help(); &:before { content: @fa-var-file-audio-o; }}

//.fa-file-video-o(){.fa-icon-help(); &:before { content: @fa-var-file-video-o; }}

//.fa-file-code-o(){.fa-icon-help(); &:before { content: @fa-var-file-code-o; }}

.fa-vine()
{.fa-icon-help();
  &:before
  { content: @fa-var-vine; }
}

.fa-codepen()
{.fa-icon-help();
  &:before
  { content: @fa-var-codepen; }
}

.fa-jsfiddle()
{.fa-icon-help();
  &:before
  { content: @fa-var-jsfiddle; }
}

.fa-life-ring()
{.fa-icon-help();
  &:before
  { content: @fa-var-life-ring; }
}

//.fa-circle-o-notch(){.fa-icon-help(); &:before { content: @fa-var-circle-o-notch; }}

.fa-rebel()
{.fa-icon-help();
  &:before
  { content: @fa-var-rebel; }
}

.fa-empire()
{.fa-icon-help();
  &:before
  { content: @fa-var-empire; }
}

.fa-git-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-git-square; }
}

.fa-git()
{.fa-icon-help();
  &:before
  { content: @fa-var-git; }
}

.fa-hacker-news()
{.fa-icon-help();
  &:before
  { content: @fa-var-hacker-news; }
}

.fa-tencent-weibo()
{.fa-icon-help();
  &:before
  { content: @fa-var-tencent-weibo; }
}

.fa-qq()
{.fa-icon-help();
  &:before
  { content: @fa-var-qq; }
}

.fa-weixin()
{.fa-icon-help();
  &:before
  { content: @fa-var-weixin; }
}

.fa-paper-plane()
{.fa-icon-help();
  &:before
  { content: @fa-var-paper-plane; }
}

//.fa-paper-plane-o(){.fa-icon-help(); &:before { content: @fa-var-paper-plane-o; }}

.fa-history()
{.fa-icon-help();
  &:before
  { content: @fa-var-history; }
}

//.fa-circle-thin(){.fa-icon-help(); &:before { content: @fa-var-circle-thin; }}

//.fa-header(){.fa-icon-help(); &:before { content: @fa-var-header; }}

.fa-paragraph()
{.fa-icon-help();
  &:before
  { content: @fa-var-paragraph; }
}

//.fa-sliders(){.fa-icon-help(); &:before { content: @fa-var-sliders; }}

.fa-share-alt()
{.fa-icon-help();
  &:before
  { content: @fa-var-share-alt; }
}

.fa-share-alt-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-share-alt-square; }
}

.fa-bomb()
{.fa-icon-help();
  &:before
  { content: @fa-var-bomb; }
}

//.fa-futbol-o(){.fa-icon-help(); &:before { content: @fa-var-futbol-o; }}

.fa-tty()
{.fa-icon-help();
  &:before
  { content: @fa-var-tty; }
}

.fa-binoculars()
{.fa-icon-help();
  &:before
  { content: @fa-var-binoculars; }
}

.fa-plug()
{.fa-icon-help();
  &:before
  { content: @fa-var-plug; }
}

.fa-slideshare()
{.fa-icon-help();
  &:before
  { content: @fa-var-slideshare; }
}

.fa-twitch()
{.fa-icon-help();
  &:before
  { content: @fa-var-twitch; }
}

.fa-yelp()
{.fa-icon-help();
  &:before
  { content: @fa-var-yelp; }
}

//.fa-newspaper-o(){.fa-icon-help(); &:before { content: @fa-var-newspaper-o; }}

.fa-wifi()
{.fa-icon-help();
  &:before
  { content: @fa-var-wifi; }
}

.fa-calculator()
{.fa-icon-help();
  &:before
  { content: @fa-var-calculator; }
}

.fa-paypal()
{.fa-icon-help();
  &:before
  { content: @fa-var-paypal; }
}

.fa-google-wallet()
{.fa-icon-help();
  &:before
  { content: @fa-var-google-wallet; }
}

.fa-cc-visa()
{.fa-icon-help();
  &:before
  { content: @fa-var-cc-visa; }
}

.fa-cc-mastercard()
{.fa-icon-help();
  &:before
  { content: @fa-var-cc-mastercard; }
}

.fa-cc-discover()
{.fa-icon-help();
  &:before
  { content: @fa-var-cc-discover; }
}

.fa-cc-amex()
{.fa-icon-help();
  &:before
  { content: @fa-var-cc-amex; }
}

.fa-cc-paypal()
{.fa-icon-help();
  &:before
  { content: @fa-var-cc-paypal; }
}

.fa-cc-stripe()
{.fa-icon-help();
  &:before
  { content: @fa-var-cc-stripe; }
}

.fa-bell-slash()
{.fa-icon-help();
  &:before
  { content: @fa-var-bell-slash; }
}

//.fa-bell-slash-o(){.fa-icon-help(); &:before { content: @fa-var-bell-slash-o; }}

.fa-trash()
{.fa-icon-help();
  &:before
  { content: @fa-var-trash; }
}

.fa-copyright()
{.fa-icon-help();
  &:before
  { content: @fa-var-copyright; }
}

.fa-at()
{.fa-icon-help();
  &:before
  { content: @fa-var-at; }
}

//.fa-eyedropper(){.fa-icon-help(); &:before { content: @fa-var-eyedropper; }}

.fa-paint-brush()
{.fa-icon-help();
  &:before
  { content: @fa-var-paint-brush; }
}

.fa-birthday-cake()
{.fa-icon-help();
  &:before
  { content: @fa-var-birthday-cake; }
}

//.fa-area-chart(){.fa-icon-help(); &:before { content: @fa-var-area-chart; }}

//.fa-pie-chart(){.fa-icon-help(); &:before { content: @fa-var-pie-chart; }}

//.fa-line-chart(){.fa-icon-help(); &:before { content: @fa-var-line-chart; }}

.fa-lastfm()
{.fa-icon-help();
  &:before
  { content: @fa-var-lastfm; }
}

.fa-lastfm-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-lastfm-square; }
}

.fa-toggle-off()
{.fa-icon-help();
  &:before
  { content: @fa-var-toggle-off; }
}

.fa-toggle-on()
{.fa-icon-help();
  &:before
  { content: @fa-var-toggle-on; }
}

.fa-bicycle()
{.fa-icon-help();
  &:before
  { content: @fa-var-bicycle; }
}

.fa-bus()
{.fa-icon-help();
  &:before
  { content: @fa-var-bus; }
}

.fa-ioxhost()
{.fa-icon-help();
  &:before
  { content: @fa-var-ioxhost; }
}

.fa-angellist()
{.fa-icon-help();
  &:before
  { content: @fa-var-angellist; }
}

//.fa-cc(){.fa-icon-help(); &:before { content: @fa-var-cc; }}

//.fa-ils(){.fa-icon-help(); &:before { content: @fa-var-ils; }}

//.fa-meanpath(){.fa-icon-help(); &:before { content: @fa-var-meanpath; }}

.fa-buysellads()
{.fa-icon-help();
  &:before
  { content: @fa-var-buysellads; }
}

.fa-connectdevelop()
{.fa-icon-help();
  &:before
  { content: @fa-var-connectdevelop; }
}

.fa-dashcube()
{.fa-icon-help();
  &:before
  { content: @fa-var-dashcube; }
}

.fa-forumbee()
{.fa-icon-help();
  &:before
  { content: @fa-var-forumbee; }
}

.fa-leanpub()
{.fa-icon-help();
  &:before
  { content: @fa-var-leanpub; }
}

.fa-sellsy()
{.fa-icon-help();
  &:before
  { content: @fa-var-sellsy; }
}

.fa-shirtsinbulk()
{.fa-icon-help();
  &:before
  { content: @fa-var-shirtsinbulk; }
}

.fa-simplybuilt()
{.fa-icon-help();
  &:before
  { content: @fa-var-simplybuilt; }
}

.fa-skyatlas()
{.fa-icon-help();
  &:before
  { content: @fa-var-skyatlas; }
}

.fa-cart-plus()
{.fa-icon-help();
  &:before
  { content: @fa-var-cart-plus; }
}

.fa-cart-arrow-down()
{.fa-icon-help();
  &:before
  { content: @fa-var-cart-arrow-down; }
}

//.fa-diamond(){.fa-icon-help(); &:before { content: @fa-var-diamond; }}

.fa-ship()
{.fa-icon-help();
  &:before
  { content: @fa-var-ship; }
}

.fa-user-secret()
{.fa-icon-help();
  &:before
  { content: @fa-var-user-secret; }
}

.fa-motorcycle()
{.fa-icon-help();
  &:before
  { content: @fa-var-motorcycle; }
}

.fa-street-view()
{.fa-icon-help();
  &:before
  { content: @fa-var-street-view; }
}

.fa-heartbeat()
{.fa-icon-help();
  &:before
  { content: @fa-var-heartbeat; }
}

.fa-venus()
{.fa-icon-help();
  &:before
  { content: @fa-var-venus; }
}

.fa-mars()
{.fa-icon-help();
  &:before
  { content: @fa-var-mars; }
}

.fa-mercury()
{.fa-icon-help();
  &:before
  { content: @fa-var-mercury; }
}

.fa-transgender()
{.fa-icon-help();
  &:before
  { content: @fa-var-transgender; }
}

.fa-transgender-alt()
{.fa-icon-help();
  &:before
  { content: @fa-var-transgender-alt; }
}

.fa-venus-double()
{.fa-icon-help();
  &:before
  { content: @fa-var-venus-double; }
}

.fa-mars-double()
{.fa-icon-help();
  &:before
  { content: @fa-var-mars-double; }
}

.fa-venus-mars()
{.fa-icon-help();
  &:before
  { content: @fa-var-venus-mars; }
}

.fa-mars-stroke()
{.fa-icon-help();
  &:before
  { content: @fa-var-mars-stroke; }
}

.fa-mars-stroke-v()
{.fa-icon-help();
  &:before
  { content: @fa-var-mars-stroke-v; }
}

.fa-mars-stroke-h()
{.fa-icon-help();
  &:before
  { content: @fa-var-mars-stroke-h; }
}

.fa-neuter()
{.fa-icon-help();
  &:before
  { content: @fa-var-neuter; }
}

.fa-genderless()
{.fa-icon-help();
  &:before
  { content: @fa-var-genderless; }
}

//.fa-facebook-official(){.fa-icon-help(); &:before { content: @fa-var-facebook-official; }}

.fa-pinterest-p()
{.fa-icon-help();
  &:before
  { content: @fa-var-pinterest-p; }
}

.fa-whatsapp()
{.fa-icon-help();
  &:before
  { content: @fa-var-whatsapp; }
}

.fa-server()
{.fa-icon-help();
  &:before
  { content: @fa-var-server; }
}

.fa-user-plus()
{.fa-icon-help();
  &:before
  { content: @fa-var-user-plus; }
}

.fa-user-times()
{.fa-icon-help();
  &:before
  { content: @fa-var-user-times; }
}

.fa-bed()
{.fa-icon-help();
  &:before
  { content: @fa-var-bed; }
}

.fa-viacoin()
{.fa-icon-help();
  &:before
  { content: @fa-var-viacoin; }
}

.fa-train()
{.fa-icon-help();
  &:before
  { content: @fa-var-train; }
}

.fa-subway()
{.fa-icon-help();
  &:before
  { content: @fa-var-subway; }
}

.fa-medium()
{.fa-icon-help();
  &:before
  { content: @fa-var-medium; }
}

.fa-y-combinator()
{.fa-icon-help();
  &:before
  { content: @fa-var-y-combinator; }
}

.fa-optin-monster()
{.fa-icon-help();
  &:before
  { content: @fa-var-optin-monster; }
}

.fa-opencart()
{.fa-icon-help();
  &:before
  { content: @fa-var-opencart; }
}

.fa-expeditedssl()
{.fa-icon-help();
  &:before
  { content: @fa-var-expeditedssl; }
}

.fa-battery-full()
{.fa-icon-help();
  &:before
  { content: @fa-var-battery-full; }
}

.fa-battery-three-quarters()
{.fa-icon-help();
  &:before
  { content: @fa-var-battery-three-quarters; }
}

.fa-battery-half()
{.fa-icon-help();
  &:before
  { content: @fa-var-battery-half; }
}

.fa-battery-quarter()
{.fa-icon-help();
  &:before
  { content: @fa-var-battery-quarter; }
}

.fa-battery-empty()
{.fa-icon-help();
  &:before
  { content: @fa-var-battery-empty; }
}

.fa-mouse-pointer()
{.fa-icon-help();
  &:before
  { content: @fa-var-mouse-pointer; }
}

.fa-i-cursor()
{.fa-icon-help();
  &:before
  { content: @fa-var-i-cursor; }
}

.fa-object-group()
{.fa-icon-help();
  &:before
  { content: @fa-var-object-group; }
}

.fa-object-ungroup()
{.fa-icon-help();
  &:before
  { content: @fa-var-object-ungroup; }
}

.fa-sticky-note()
{.fa-icon-help();
  &:before
  { content: @fa-var-sticky-note; }
}

//.fa-sticky-note-o(){.fa-icon-help(); &:before { content: @fa-var-sticky-note-o; }}

.fa-cc-jcb()
{.fa-icon-help();
  &:before
  { content: @fa-var-cc-jcb; }
}

.fa-cc-diners-club()
{.fa-icon-help();
  &:before
  { content: @fa-var-cc-diners-club; }
}

.fa-clone()
{.fa-icon-help();
  &:before
  { content: @fa-var-clone; }
}

.fa-balance-scale()
{.fa-icon-help();
  &:before
  { content: @fa-var-balance-scale; }
}

//.fa-hourglass-o(){.fa-icon-help(); &:before { content: @fa-var-hourglass-o; }}

.fa-hourglass-start()
{.fa-icon-help();
  &:before
  { content: @fa-var-hourglass-start; }
}

.fa-hourglass-half()
{.fa-icon-help();
  &:before
  { content: @fa-var-hourglass-half; }
}

.fa-hourglass-end()
{.fa-icon-help();
  &:before
  { content: @fa-var-hourglass-end; }
}

.fa-hourglass()
{.fa-icon-help();
  &:before
  { content: @fa-var-hourglass; }
}

//.fa-hand-rock-o(){.fa-icon-help(); &:before { content: @fa-var-hand-rock-o; }}

//.fa-hand-paper-o(){.fa-icon-help(); &:before { content: @fa-var-hand-paper-o; }}

//.fa-hand-scissors-o(){.fa-icon-help(); &:before { content: @fa-var-hand-scissors-o; }}

//.fa-hand-lizard-o(){.fa-icon-help(); &:before { content: @fa-var-hand-lizard-o; }}

//.fa-hand-spock-o(){.fa-icon-help(); &:before { content: @fa-var-hand-spock-o; }}

//.fa-hand-pointer-o(){.fa-icon-help(); &:before { content: @fa-var-hand-pointer-o; }}

//.fa-hand-peace-o(){.fa-icon-help(); &:before { content: @fa-var-hand-peace-o; }}

.fa-trademark()
{.fa-icon-help();
  &:before
  { content: @fa-var-trademark; }
}

.fa-registered()
{.fa-icon-help();
  &:before
  { content: @fa-var-registered; }
}

.fa-creative-commons()
{.fa-icon-help();
  &:before
  { content: @fa-var-creative-commons; }
}

.fa-gg()
{.fa-icon-help();
  &:before
  { content: @fa-var-gg; }
}

.fa-gg-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-gg-circle; }
}

.fa-tripadvisor()
{.fa-icon-help();
  &:before
  { content: @fa-var-tripadvisor; }
}

.fa-odnoklassniki()
{.fa-icon-help();
  &:before
  { content: @fa-var-odnoklassniki; }
}

.fa-odnoklassniki-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-odnoklassniki-square; }
}

.fa-get-pocket()
{.fa-icon-help();
  &:before
  { content: @fa-var-get-pocket; }
}

.fa-wikipedia-w()
{.fa-icon-help();
  &:before
  { content: @fa-var-wikipedia-w; }
}

.fa-safari()
{.fa-icon-help();
  &:before
  { content: @fa-var-safari; }
}

.fa-chrome()
{.fa-icon-help();
  &:before
  { content: @fa-var-chrome; }
}

.fa-firefox()
{.fa-icon-help();
  &:before
  { content: @fa-var-firefox; }
}

.fa-opera()
{.fa-icon-help();
  &:before
  { content: @fa-var-opera; }
}

.fa-internet-explorer()
{.fa-icon-help();
  &:before
  { content: @fa-var-internet-explorer; }
}

//.fa-television(){.fa-icon-help(); &:before { content: @fa-var-television; }}

.fa-contao()
{.fa-icon-help();
  &:before
  { content: @fa-var-contao; }
}

.fa-500px()
{.fa-icon-help();
  &:before
  { content: @fa-var-500px; }
}

.fa-amazon()
{.fa-icon-help();
  &:before
  { content: @fa-var-amazon; }
}

//.fa-calendar-plus-o(){.fa-icon-help(); &:before { content: @fa-var-calendar-plus-o; }}

//.fa-calendar-minus-o(){.fa-icon-help(); &:before { content: @fa-var-calendar-minus-o; }}

//.fa-calendar-times-o(){.fa-icon-help(); &:before { content: @fa-var-calendar-times-o; }}

//.fa-calendar-check-o(){.fa-icon-help(); &:before { content: @fa-var-calendar-check-o; }}

.fa-industry()
{.fa-icon-help();
  &:before
  { content: @fa-var-industry; }
}

.fa-map-pin()
{.fa-icon-help();
  &:before
  { content: @fa-var-map-pin; }
}

.fa-map-signs()
{.fa-icon-help();
  &:before
  { content: @fa-var-map-signs; }
}

//.fa-map-o(){.fa-icon-help(); &:before { content: @fa-var-map-o; }}

.fa-map()
{.fa-icon-help();
  &:before
  { content: @fa-var-map; }
}

//.fa-commenting(){.fa-icon-help(); &:before { content: @fa-var-commenting; }}

//.fa-commenting-o(){.fa-icon-help(); &:before { content: @fa-var-commenting-o; }}

.fa-houzz()
{.fa-icon-help();
  &:before
  { content: @fa-var-houzz; }
}

.fa-vimeo()
{.fa-icon-help();
  &:before
  { content: @fa-var-vimeo; }
}

.fa-black-tie()
{.fa-icon-help();
  &:before
  { content: @fa-var-black-tie; }
}

.fa-fonticons()
{.fa-icon-help();
  &:before
  { content: @fa-var-fonticons; }
}

.fa-reddit-alien()
{.fa-icon-help();
  &:before
  { content: @fa-var-reddit-alien; }
}

.fa-edge()
{.fa-icon-help();
  &:before
  { content: @fa-var-edge; }
}

//.fa-credit-card-alt(){.fa-icon-help(); &:before { content: @fa-var-credit-card-alt; }}

.fa-codiepie()
{.fa-icon-help();
  &:before
  { content: @fa-var-codiepie; }
}

.fa-modx()
{.fa-icon-help();
  &:before
  { content: @fa-var-modx; }
}

.fa-fort-awesome()
{.fa-icon-help();
  &:before
  { content: @fa-var-fort-awesome; }
}

.fa-usb()
{.fa-icon-help();
  &:before
  { content: @fa-var-usb; }
}

.fa-product-hunt()
{.fa-icon-help();
  &:before
  { content: @fa-var-product-hunt; }
}

.fa-mixcloud()
{.fa-icon-help();
  &:before
  { content: @fa-var-mixcloud; }
}

.fa-scribd()
{.fa-icon-help();
  &:before
  { content: @fa-var-scribd; }
}

.fa-pause-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-pause-circle; }
}

//.fa-pause-circle-o(){.fa-icon-help(); &:before { content: @fa-var-pause-circle-o; }}

.fa-stop-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-stop-circle; }
}

//.fa-stop-circle-o(){.fa-icon-help(); &:before { content: @fa-var-stop-circle-o; }}

.fa-shopping-bag()
{.fa-icon-help();
  &:before
  { content: @fa-var-shopping-bag; }
}

.fa-shopping-basket()
{.fa-icon-help();
  &:before
  { content: @fa-var-shopping-basket; }
}

.fa-hashtag()
{.fa-icon-help();
  &:before
  { content: @fa-var-hashtag; }
}

.fa-bluetooth()
{.fa-icon-help();
  &:before
  { content: @fa-var-bluetooth; }
}

.fa-bluetooth-b()
{.fa-icon-help();
  &:before
  { content: @fa-var-bluetooth-b; }
}

.fa-percent()
{.fa-icon-help();
  &:before
  { content: @fa-var-percent; }
}

.fa-gitlab()
{.fa-icon-help();
  &:before
  { content: @fa-var-gitlab; }
}

.fa-wpbeginner()
{.fa-icon-help();
  &:before
  { content: @fa-var-wpbeginner; }
}

.fa-wpforms()
{.fa-icon-help();
  &:before
  { content: @fa-var-wpforms; }
}

.fa-envira()
{.fa-icon-help();
  &:before
  { content: @fa-var-envira; }
}

.fa-universal-access()
{.fa-icon-help();
  &:before
  { content: @fa-var-universal-access; }
}

//.fa-wheelchair-alt(){.fa-icon-help(); &:before { content: @fa-var-wheelchair-alt; }}

//.fa-question-circle-o(){.fa-icon-help(); &:before { content: @fa-var-question-circle-o; }}

.fa-blind()
{.fa-icon-help();
  &:before
  { content: @fa-var-blind; }
}

.fa-audio-description()
{.fa-icon-help();
  &:before
  { content: @fa-var-audio-description; }
}

//.fa-volume-control-phone(){.fa-icon-help(); &:before { content: @fa-var-volume-control-phone; }}

.fa-braille()
{.fa-icon-help();
  &:before
  { content: @fa-var-braille; }
}

.fa-assistive-listening-systems()
{.fa-icon-help();
  &:before
  { content: @fa-var-assistive-listening-systems; }
}

.fa-american-sign-language-interpreting()
{.fa-icon-help();
  &:before
  { content: @fa-var-american-sign-language-interpreting; }
}

.fa-deaf()
{.fa-icon-help();
  &:before
  { content: @fa-var-deaf; }
}

.fa-glide()
{.fa-icon-help();
  &:before
  { content: @fa-var-glide; }
}

.fa-glide-g()
{.fa-icon-help();
  &:before
  { content: @fa-var-glide-g; }
}

.fa-sign-language()
{.fa-icon-help();
  &:before
  { content: @fa-var-sign-language; }
}

.fa-low-vision()
{.fa-icon-help();
  &:before
  { content: @fa-var-low-vision; }
}

.fa-viadeo()
{.fa-icon-help();
  &:before
  { content: @fa-var-viadeo; }
}

.fa-viadeo-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-viadeo-square; }
}

.fa-snapchat()
{.fa-icon-help();
  &:before
  { content: @fa-var-snapchat; }
}

.fa-snapchat-ghost()
{.fa-icon-help();
  &:before
  { content: @fa-var-snapchat-ghost; }
}

.fa-snapchat-square()
{.fa-icon-help();
  &:before
  { content: @fa-var-snapchat-square; }
}

.fa-pied-piper()
{.fa-icon-help();
  &:before
  { content: @fa-var-pied-piper; }
}

.fa-first-order()
{.fa-icon-help();
  &:before
  { content: @fa-var-first-order; }
}

.fa-yoast()
{.fa-icon-help();
  &:before
  { content: @fa-var-yoast; }
}

.fa-themeisle()
{.fa-icon-help();
  &:before
  { content: @fa-var-themeisle; }
}

//.fa-google-plus-official(){.fa-icon-help(); &:before { content: @fa-var-google-plus-official; }}

.fa-font-awesome()
{.fa-icon-help();
  &:before
  { content: @fa-var-font-awesome; }
}

//.fa-handshake-o(){.fa-icon-help(); &:before { content: @fa-var-handshake-o; }}

.fa-envelope-open()
{.fa-icon-help();
  &:before
  { content: @fa-var-envelope-open; }
}

//.fa-envelope-open-o(){.fa-icon-help(); &:before { content: @fa-var-envelope-open-o; }}

.fa-linode()
{.fa-icon-help();
  &:before
  { content: @fa-var-linode; }
}

.fa-address-book()
{.fa-icon-help();
  &:before
  { content: @fa-var-address-book; }
}

//.fa-address-book-o(){.fa-icon-help(); &:before { content: @fa-var-address-book-o; }}

.fa-address-card()
{.fa-icon-help();
  &:before
  { content: @fa-var-address-card; }
}

//.fa-address-card-o(){.fa-icon-help(); &:before { content: @fa-var-address-card-o; }}

.fa-user-circle()
{.fa-icon-help();
  &:before
  { content: @fa-var-user-circle; }
}

//.fa-user-circle-o(){.fa-icon-help(); &:before { content: @fa-var-user-circle-o; }}

//.fa-user-o(){.fa-icon-help(); &:before { content: @fa-var-user-o; }}

.fa-id-badge()
{.fa-icon-help();
  &:before
  { content: @fa-var-id-badge; }
}

.fa-id-card()
{.fa-icon-help();
  &:before
  { content: @fa-var-id-card; }
}

//.fa-id-card-o(){.fa-icon-help(); &:before { content: @fa-var-id-card-o; }}

.fa-quora()
{.fa-icon-help();
  &:before
  { content: @fa-var-quora; }
}

.fa-free-code-camp()
{.fa-icon-help();
  &:before
  { content: @fa-var-free-code-camp; }
}

.fa-telegram()
{.fa-icon-help();
  &:before
  { content: @fa-var-telegram; }
}

.fa-thermometer-full()
{.fa-icon-help();
  &:before
  { content: @fa-var-thermometer-full; }
}

.fa-thermometer-three-quarters()
{.fa-icon-help();
  &:before
  { content: @fa-var-thermometer-three-quarters; }
}

.fa-thermometer-half()
{.fa-icon-help();
  &:before
  { content: @fa-var-thermometer-half; }
}

.fa-thermometer-quarter()
{.fa-icon-help();
  &:before
  { content: @fa-var-thermometer-quarter; }
}

.fa-thermometer-empty()
{.fa-icon-help();
  &:before
  { content: @fa-var-thermometer-empty; }
}

.fa-shower()
{.fa-icon-help();
  &:before
  { content: @fa-var-shower; }
}

.fa-bath()
{.fa-icon-help();
  &:before
  { content: @fa-var-bath; }
}

.fa-podcast()
{.fa-icon-help();
  &:before
  { content: @fa-var-podcast; }
}

.fa-window-maximize()
{.fa-icon-help();
  &:before
  { content: @fa-var-window-maximize; }
}

.fa-window-minimize()
{.fa-icon-help();
  &:before
  { content: @fa-var-window-minimize; }
}

.fa-window-restore()
{.fa-icon-help();
  &:before
  { content: @fa-var-window-restore; }
}

.fa-window-close()
{.fa-icon-help();
  &:before
  { content: @fa-var-window-close; }
}

//.fa-window-close-o(){.fa-icon-help(); &:before { content: @fa-var-window-close-o; }}

.fa-bandcamp()
{.fa-icon-help();
  &:before
  { content: @fa-var-bandcamp; }
}

.fa-grav()
{.fa-icon-help();
  &:before
  { content: @fa-var-grav; }
}

.fa-etsy()
{.fa-icon-help();
  &:before
  { content: @fa-var-etsy; }
}

.fa-imdb()
{.fa-icon-help();
  &:before
  { content: @fa-var-imdb; }
}

.fa-ravelry()
{.fa-icon-help();
  &:before
  { content: @fa-var-ravelry; }
}

//.fa-eercast(){.fa-icon-help(); &:before { content: @fa-var-eercast; }}

.fa-microchip()
{.fa-icon-help();
  &:before
  { content: @fa-var-microchip; }
}

//.fa-snowflake-o(){.fa-icon-help(); &:before { content: @fa-var-snowflake-o; }}

.fa-superpowers()
{.fa-icon-help();
  &:before
  { content: @fa-var-superpowers; }
}

.fa-wpexplorer()
{.fa-icon-help();
  &:before
  { content: @fa-var-wpexplorer; }
}

.fa-meetup()
{.fa-icon-help();
  &:before
  { content: @fa-var-meetup; }
}
